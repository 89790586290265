<template>
  <div class="px-2 py-3">
    <v-text-field
      v-model="search"
      label="搜尋"
      outlined
      rounded
      dense
      hide-details
      clearable
      clear-icon="mdi-close"
    ></v-text-field>
    <div v-if="tags">
      <router-link
        :to="tags.to"
        tag="div"
        class="pt-3 px-2 accent--text font-weight-bold"
        style="cursor: pointer"
      >
        {{ tags.name }}
      </router-link>
    </div>
    <v-treeview
      ref="options"
      :items="menuItems"
      :search="search"
      :filter="filter"
      item-key="id"
      item-text="displayName"
      item-children="branch"
      rounded
      open-all
      return-object
      hoverable
      dense
      open-on-click
    >
      <template v-slot:label="{ item }">
        <router-link :to="item.to" custom v-slot="{ navigate }">
          <div
            @click="navigate"
            :class="[
              'nav',
              { 'secondary--text': tagPath === item.id },
              { 'accent--text': tagPath !== item.id }
            ]"
          >
            <v-icon :color="tagPath === item.id ? 'secondary' : 'accent'">{{
              item.icon
            }}</v-icon>
            {{ item.displayName }}
          </div>
        </router-link>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LeftSideBar',
  props: {},
  components: {},
  data() {
    return {
      search: null,
      caseSensitive: false
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags'
    }),
    tagPath() {
      return this.$route.params.tagId || ''
    },
    menuItems() {
      if (!this.tags) return []
      return this.tags.branch || []
    },
    filter() {
      // 區分大小寫，自定義
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  },
  methods: {
    ...mapActions({
      getTags: 'page/getTags'
    })
  },
  async mounted() {
    if (!this.tags) {
      await this.getTags()
      await this.$nextTick()
      this.$refs.options.updateAll(true)
    }
  }
}
</script>

<style lang="scss" scoped></style>
