var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 py-3"},[_c('v-text-field',{attrs:{"label":"搜尋","outlined":"","rounded":"","dense":"","hide-details":"","clearable":"","clear-icon":"mdi-close"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tags)?_c('div',[_c('router-link',{staticClass:"pt-3 px-2 accent--text font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"to":_vm.tags.to,"tag":"div"}},[_vm._v(" "+_vm._s(_vm.tags.name)+" ")])],1):_vm._e(),_c('v-treeview',{ref:"options",attrs:{"items":_vm.menuItems,"search":_vm.search,"filter":_vm.filter,"item-key":"id","item-text":"displayName","item-children":"branch","rounded":"","open-all":"","return-object":"","hoverable":"","dense":"","open-on-click":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":item.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{class:[
            'nav',
            { 'secondary--text': _vm.tagPath === item.id },
            { 'accent--text': _vm.tagPath !== item.id }
          ],on:{"click":navigate}},[_c('v-icon',{attrs:{"color":_vm.tagPath === item.id ? 'secondary' : 'accent'}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.displayName)+" ")],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }